<template>
  <div>
    <img
      v-if="isCard && service == 'Domain Name'"
      src="@/assets/img/service_illustrations/brand_domain_illustration_card.svg"
      width="100%"
    />
    <img
      v-if="isCard && service == 'Brand Logo'"
      src="@/assets/img/service_illustrations/brand_logo_illustration_card.svg"
      width="100%"
    />
    <img
      v-if="isCard && service == 'Brand Name'"
      src="@/assets/img/service_illustrations/brand_name_illustration_card.svg"
      width="100%"
    />
    <img
      v-if="isCard && service == 'Brand Promise'"
      src="@/assets/img/service_illustrations/brand_promise_illustration_card.svg"
      width="100%"
    />
    <img
      v-if="isCard && service == 'Company Incorporation'"
      src="@/assets/img/service_illustrations/company_formation_illustration_card.svg"
      width="100%"
    />
    <img
      v-if="isCard && service == 'Trademark Registration'"
      src="@/assets/img/service_illustrations/trademark_protection_card.svg"
      width="100%"
    />
    <img
      v-if="
        (isCard && service == 'Brand Templates') || service == 'Brand Product'
      "
      src="@/assets/img/service_illustrations/visual_ID_guidelines_illustration_card.svg"
      width="100%"
    />
    <img
      v-if="isCard && service == 'Customized Service'"
      src="@/assets/img/service_illustrations/custom_illustration_card.svg"
      width="100%"
    />
    <img
      v-if="!isCard && service == 'Domain Name'"
      src="@/assets/img/service_illustrations/brand_domain_illustration.svg"
      width="100%"
    />
    <img
      v-if="!isCard && service == 'Brand Logo'"
      src="@/assets/img/service_illustrations/brand_logo_illustration.svg"
      width="100%"
    />
    <img
      v-if="!isCard && service == 'Brand Name'"
      src="@/assets/img/service_illustrations/brand_name_illustration.svg"
      width="100%"
    />
    <img
      v-if="!isCard && service == 'Brand Promise'"
      src="@/assets/img/service_illustrations/brand_promise_illustration.svg"
      width="100%"
    />
    <img
      v-if="!isCard && service == 'Company Incorporation'"
      src="@/assets/img/service_illustrations/company_formation_illustration.svg"
      width="100%"
    />
    <img
      v-if="!isCard && service == 'Trademark Registration'"
      src="@/assets/img/service_illustrations/trademark_protection_illustration.svg"
      width="100%"
    />
    <img
      v-if="!isCard && service == 'Brand Templates'"
      src="@/assets/img/service_illustrations/visual_ID_guidelines_illustration.svg"
      width="100%"
    />
    <img
      v-if="!isCard && service == 'Customized Service'"
      src="@/assets/img/service_illustrations/custom_illustration.svg"
      width="100%"
    />
    <img
      v-if="isCard && service == 'Brand Name + Brand Promise'"
      src="@/assets/img/service_illustrations/brand_name_brand_promise_card.svg"
      style="padding: 0; margin: 0"
    />
    <img
      v-if="isCard && service == 'Brand Logo + Logo Guide'"
      src="@/assets/img/service_illustrations/brand_logo_brand_guide_card.svg"
      style="padding: 0; margin: 0"
    />
    <img
      v-if="isCard && service == 'Brand Logo + Logo Guide + Brand Templates'"
      src="@/assets/img/service_illustrations/brand_logo_brand_guide_brand_template_card.svg"
      style="padding: 0; margin: 0"
    />
  </div>
</template>

<script>
export default {
  name: "ServiceIllustrations",
  props: {
    service: String,
    isCard: Boolean,
  },
};
</script>

<style lang="scss" scoped></style>
